import moment from 'moment';
import 'moment-timezone';
import {
    SHOW_ORGANIZATION_ALERTS,
    HIDE_ORGANIZATION_ALERTS
} from 'services/StudioEvents';
import {
    getStatusMessage
} from 'services/SensorHelper';
import { getHoursMinutesFormat, truncateString, alertTriggerDescription , isMobileLayout} from 'services/utils';
import { RANGE_LABEL_WEEK, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { CREATE_PROJECT } from 'services/Permissions';
import { States } from '../../app.router';

import TimelineController from '../../modules/alerts/alert-log/timeline/timeline.controller';
import TimelineTemplate from '../../modules/alerts/alert-log/timeline/timeline.html';

import ExtendedStorageInfoController from '../../common/extended-storage-info/controller';
import ExtendedStorageInfoTemplate from '../../common/extended-storage-info/template.html';


/* @ngInject */
export default class OrganizationDetailsController {

    constructor(IAMService, ToastService, ProjectManager, $q, RoleManager, $state, $scope, $rootScope, AlertsAndRulesService, SensorService, DialogService, FeatureFlags, AnalyticsService) {
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.$q = $q;
        this.RoleManager = RoleManager;
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.DialogService = DialogService;
        this.FeatureFlags = FeatureFlags;
        this.AnalyticsService = AnalyticsService;
    }

    get isOrganizationAdmin() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_PROJECT);
    }
 
    get numberOfOpenAlerts() {
        return this.filteredAlerts.filter(alert => alert.status === 'TRIGGERED').length;
    }

    get numberOfAcknowledgedAlerts() {
        return this.filteredAlerts.filter(alert => alert.status === 'ACKNOWLEDGED').length;
    }

    get numberOfMonitoringAlerts() {
        return this.filteredAlerts.filter(alert => alert.status === 'MONITORING').length;
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }

    $onInit() {

        this.isMobile = isMobileLayout();
        this.$rootScope.$broadcast(SHOW_ORGANIZATION_ALERTS); 

        this.project = this.ProjectManager.currentProject;
        this.organization = this.ProjectManager.currentProject.organization;
        
        this.orgName = `${this.ProjectManager.currentProject.organizationDisplayName} | Alerts`

        this.projects = []
        this.alerts = []
        this.filteredAlerts = []
        this.query = ''
        this.filteredProjects = [] // Used to limit list when a search query is entered
        this.singleRuleFilter = null
        this.editingLocation = false
        this.projectBeingEdited = null
        this.timeZoneToBeConfirmed = null
        this.loadingTimeZone = false
        this.readyToSaveLocation = false

        this.compactAlertView = true

        this.states = {
            ACTIVE_ALERTS: 'ACTIVE_ALERTS',
            LOG: 'LOG',
        }

        this.selectedProject = null

        this.activeState = this.states.ACTIVE_ALERTS

        this.filters = [];
        this.filterCount = 0;
        this.showFilters = false;

        this.dateRange = 'LAST_30_DAYS'
        this.extendedStorageDurations = ["LAST_3_MONTHS", "LAST_6_MONTHS", "LAST_12_MONTHS", "LAST_3_YEARS"]
        this.startTime = moment().subtract(30, 'day').startOf('day')
        this.endTime = moment()
        this.totalAggregatedAlerts = 0

        this.loadProjectsAndActiveAlerts()

        // Listen for when the ESC key is pressed to close the alert overview
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                this.exitAlertOverview();
            }
        })
    }

    setState(state) {
        this.activeState = state

        if (state === this.states.ACTIVE_ALERTS) {
            this.updateFilteredActiveAlerts()
        }

        if (state === this.states.LOG) {
            this.fetchAggregatedAlertsLog().then(() => {
                this.updateMapAlertLog()
                this.loadAlertLogForProject(this.selectedProject)
            })
        }
    }

    loadProjectsAndActiveAlerts() {
        const projectPromises = []
        this.projects = []
        projectPromises.push(this.fetchAllProjects().then(projects => {
            this.projects = [...projects]
        }))
        
        if (!this.ProjectManager.currentProject.organizationId) {
            this.ProjectManager.currentProject.organizationId = this.ProjectManager.currentProject.organization.split('/')[1]
        }

        const listActiveAlertsPromise = this.AlertsAndRulesService.listActiveOrganizationAlerts({}).then(alerts => {
            alerts.forEach(alert => {
                alert.showRangeDropdown = false;
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.triggerDescription = alertTriggerDescription(alert);
            });
            this.alerts = alerts;
            this.filteredAlerts = alerts;
        });

        Promise.all([...projectPromises, listActiveAlertsPromise]).then(() => {
            
            this.projects.forEach(project => {
                project.alerts = this.alerts.filter(alert => alert.project === project.name)
                project.alerts.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
                project.loadedDevices = false
            });

            this.sortProjectsActiveAlerts()
            
            this.filteredProjects = this.projects

            let initialLocation = [0, 35]
            let initialZoom = 0.8

            if (!this.isMobile) {
                if (this.selectedProject !== null) {
                    this.selectedProject = this.projects.find(project => project.id === this.selectedProject.id)
                } else {
                    this.selectedProject = this.projects[0]
                }
                const project = this.selectedProject
                if (this.selectedProject.loadedDevices === false) {
                    const deviceIds = this.selectedProject.alerts.map(alert => alert.device.split('/')[3]);
                    this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                        const devices = devicesResponse.devices;
                        this.selectedProject.alerts.forEach(alert => {
                            const device = devices.find(d => d.name === alert.device);
                            alert.device = device;
                        });
                        if (this.selectedProject.name === project.name) {
                            this.selectedProject.loadedDevices = true
                        }
                    });    
                }
                if (this.selectedProject.location.latitude !== null && this.selectedProject.location.longitude !== null) {
                    initialLocation = [this.selectedProject.location.longitude, this.selectedProject.location.latitude]
                    initialZoom = 4.5
                }
            }

            
            if (!this.map) {
                mapboxgl.accessToken = 'pk.eyJ1IjoidG9yZC1kdCIsImEiOiJjbHgxbWtvOGcwZXRuMmlzYXBvemQwNG9yIn0.bxWUKCEJfN7LbQQqji6PSA'; // eslint-disable-line
                this.map = new mapboxgl.Map({ // eslint-disable-line
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    center: initialLocation,
                    zoom: initialZoom,
                    projection: 'mercator' // Flat style
                });
    
                this.map.on('load', () => {
                    this.updateMapActiveAlerts()
                    this.setupMapListeners()
    
                    if (this.selectedProject) {
                        this.showPopup(this.selectedProject.id)
                    }
    
                    // Trick to ensure map is rendered correctly
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 50);
    
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 200);
                })    
            }

            this.loadedAlerts = true;
            this.$scope.$applyAsync();
        }).catch(error => {
            console.error('An error occurred:', error); // eslint-disable-line no-console
        });
    }

    loadAlertLogForProject(project) {
        this.singleRuleFilter = null;
        project.alertLog = [];
        project.loadingAlertLog = true;
    
        const fetchAlerts = (pageToken = null) => {
            const params = {
                projects: [`projects/${project.id}`],
                includeEvents: true,
                startTime: this.startTime.toISOString(),
                endTime: this.endTime.toISOString(),
                ...this.paramsFromFilters()
            };
    
            if (pageToken) {
                params.pageToken = pageToken;
            }
    
            return this.AlertsAndRulesService.listAlerts(params, project.id).then(response => {
                // Append the alerts from this page to the project.alertLog array
                project.alertLog = project.alertLog.concat(response.data);
    
                // Check if there's a nextPageToken in the response
                if (response.nextPageToken) {
                    // There is another page of results, so fetch it recursively
                    return fetchAlerts(response.nextPageToken);
                } 

                // No more pages, process the alerts
                project.alertLog.forEach(alert => {
                    alert.currentRange = RANGE_LABEL_WEEK;
                    alert.triggerDescription = alertTriggerDescription(alert);
                });

                const deviceIds = [...new Set(project.alertLog.map(alert => alert.device.split('/')[3]))];

                return this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                    const devices = devicesResponse.devices;
                    project.alertLog.forEach(alert => {
                        const device = devices.find(d => d.name === alert.device);
                        alert.device = device;
                    });

                    project.alertLog.sort((a, b) => {
                        return new Date(b.resolveTime || b.archiveTime || b.createTime) - new Date(a.resolveTime || a.archiveTime || a.createTime);
                    });
                    project.groupedAlertsByDay = this.groupAlertsByDay(project.alertLog);
                    project.groupedAlertsByRule = this.groupAlertsByRule(project.alertLog);
                    this.selectedProject = project;
                    this.$scope.$applyAsync();
                });
                
            }).catch(error => {
                console.error('An error occurred:', error); // eslint-disable-line no-console
            }).finally(() => {
                project.loadingAlertLog = false;
            });

        };
    
        // Start fetching alerts from the first page
        fetchAlerts();
    }

    sortProjectsActiveAlerts() {
        // Sort projects based on the number of alerts, then by ABC
        this.projects.sort((a, b) => {
            if (a.alerts.length > b.alerts.length) return -1
            if (a.alerts.length < b.alerts.length) return 1
            if (a.displayName < b.displayName) return -1
            if (a.displayName > b.displayName) return 1
            return 0
        });
    }

    statusMessageForDevice(device) {
        return getStatusMessage(device);
    }

    isValueBasedSensor(device) {
        return device.type === 'temperature' || device.type === 'humidity' || device.type === 'co2';
    }

    filterOnSingleRule(ruleName) {
        this.singleRuleFilter = ruleName;
        this.selectedProject.groupedAlertsByDay = this.groupAlertsByDay(this.selectedProject.alertLog);
    }

    resetSingleRuleFilter() {
        this.singleRuleFilter = null;
        this.selectedProject.groupedAlertsByDay = this.groupAlertsByDay(this.selectedProject.alertLog);
    }

    groupAlertsByDay(alerts) {
        // Group alerts by day
        const groupedAlerts = [];
        const now = moment();
        alerts.forEach(alert => {

            if (this.singleRuleFilter && alert.rule !== this.singleRuleFilter) {
                return;
            }
            
            let alertDate = moment(alert.createTime)
            if (now.diff(alertDate, 'days') <= 6) {
                alertDate = alertDate.calendar(null, {
                    sameDay: `[Today], MMMM D, YYYY`,
                    lastDay: `[Yesterday], MMMM D, YYYY`,
                    lastWeek: 'dddd, MMMM D, YYYY',
                    sameElse: 'dddd, MMMM D, YYYY'
                });
            } else {
                alertDate = moment(alert.createTime).format('dddd, MMMM D, YYYY');
            }

            const day = groupedAlerts.find(d => d.date === alertDate);
            if (day) {
                day.alerts.push(alert);
            } else {
                groupedAlerts.push({
                    date: alertDate,
                    alerts: [alert]
                });
            }
        });
        return groupedAlerts;
    }

    groupAlertsByRule(alerts) {

        const groupedAlerts = {}
        alerts.forEach(alert => {
            const alertRule = alert.rule;
            const alertDisplayName = alert.displayName;
            if (groupedAlerts[alertRule]) {
                groupedAlerts[alertRule].alerts.push(alert);
            } else {
                groupedAlerts[alertRule] = {
                    displayName: alertDisplayName,
                    alerts: [alert],
                    ruleName: alertRule
                }
            }            
        })

        // Sort by alerts count, descending
        return Object.values(groupedAlerts).sort((a, b) => {
            return b.alerts.length - a.alerts.length;
        })
    }


    paramsFromFilters() {

        const params = {}

        this.filters.forEach(filter => {
            if (filter.active) {
                if (filter.displayName === 'Alert Status') {
                    params.statuses = filter.options
                        .filter(option => option.active)
                        .map(option => option.value);
                }
                if (filter.displayName === 'Alert Type') {
                    params.triggers = []
                    const triggerFields = filter.options
                        .filter(option => option.active)
                        .map(option => option.value);
        
                    triggerFields.forEach(field => {
                        let fieldName = field;
                        let triggerString = '';
        
                        if (field === 'SENSOR_OFFLINE' || field === 'CLOUD_CONNECTOR_OFFLINE') {
                            fieldName = 'connectionStatus';
                            triggerString = `field:${fieldName},connection:${field}`;
                        } else {
                            triggerString = `field:${fieldName}`;
                        }
        
                        params.triggers.push(triggerString);
                    });
                }
                if (filter.displayName === 'Corrective Action') {
                    params.correctiveAction = filter.optionValue
                }

                if (filter.displayName === 'Acknowledged') {
                    params.acknowledgement = filter.optionValue
                }

                if (filter.displayName === 'Duration') {
                    params.maxDuration = filter.options.find(option => option.value === filter.optionValue).maxDuration
                    params.minDuration = filter.options.find(option => option.value === filter.optionValue).minDuration
                }

                if (filter.displayName === 'Device Labels') {
                    params.labelFilters = []
                    // Labels are in the format [[key, value], [key, value]]
                    if (filter.labels.length === 1 && filter.labels[0][0] === '' && filter.labels[0][1] === '') {
                        return // No labels to filter on, only the empty placeholder
                    }
                    filter.labels.forEach(label => {
                        if (label[1] === '') { // If the value is empty, only use the key
                            params.labelFilters.push(label[0])
                        } else {
                            params.labelFilters.push(`${label[0]}=${label[1]}`)
                        }
                    });
                }
            }
        })

        return params
    }


    fetchAggregatedAlertsLog() {
        
        const params = {
            startTime: this.startTime.toISOString(),
            endTime: this.endTime.toISOString(),
            ...this.paramsFromFilters()
        };
        
        return this.AlertsAndRulesService.listAggregatedAlerts(params).then(response => {
            this.totalAggregatedAlertsCount = response.data.totals.totalAlertCount
            
            this.projects.forEach(project => {
                if (response.data.projects[`projects/${project.id}`]) {
                    const projectData = response.data.projects[`projects/${project.id}`]
                    project.totalAggregatedAlertCount = projectData.totalAlertCount
                    project.meanTimeToResolve = projectData.meanTimeToResolve
                } else {
                    project.totalAggregatedAlertCount = 0
                    project.meanTimeToResolve = null
                }
            })

            // Sort projects based on totalAggregatedAlertCount, then by ABC
            this.projects.sort((a, b) => {
                if (a.totalAggregatedAlertCount > b.totalAggregatedAlertCount) return -1
                if (a.totalAggregatedAlertCount < b.totalAggregatedAlertCount) return 1
                if (a.displayName < b.displayName) return -1
                if (a.displayName > b.displayName) return 1
                return 0
            });
        
        }).catch(error => {
            console.error('An error occurred:', error); // eslint-disable-line no-console
        })
    }


    showFilterOptions() {
        this.setupFilters()
        this.showFilters = !this.showFilters

    }

    clearAllOptions(filter) {
        filter.options.forEach(option => {
            option.active = false;
        });
        this.updatedFilters();
    }

    setupFilters() {
        
        if (this.filters.length === 0) {
            this.filters.push(
                {
                    displayName: "Alert Type",
                    active: false,
                    options: [
                        {
                            displayName: "Sensor Offline",
                            value: "SENSOR_OFFLINE",
                            active: true
                        },
                        {
                            displayName: "Cloud Connector Offline",
                            value: "CLOUD_CONNECTOR_OFFLINE",
                            active: true
                        },
                        {
                            displayName: "Temperature",
                            value: "temperature",
                            active: true
                        },
                        {
                            displayName: "Humidity",
                            value: "relativeHumidity",
                            active: true
                        },
                        {
                            displayName: "CO2",
                            value: "co2",
                            active: true
                        },
                        {
                            displayName: "Door & Window",
                            value: "contact",
                            active: true
                        },
                        {
                            displayName: "Proximity",
                            value: "objectPresent",
                            active: true
                        },
                        {
                            displayName: "Water Detected",
                            value: "waterPresent",
                            active: true
                        },
                        {
                            displayName: "Touch",
                            value: "touch",
                            active: true
                        }
                    ],
                },
                {
                    displayName: "Alert Status",
                    active: false,
                    options: [
                        {
                            displayName: "Open",
                            value: "TRIGGERED",
                            active: true
                        },
                        {
                            displayName: "Acknowledged",
                            value: "ACKNOWLEDGED",
                            active: true
                        },
                        {
                            displayName: "Monitoring",
                            value: "MONITORING",
                            active: true
                        },
                        {
                            displayName: "Resolved",
                            value: "RESOLVED",
                            active: true
                        },
                        {
                            displayName: "Archived",
                            value: "ARCHIVED",
                            active: true
                        }
                    ],
                },
                {
                    displayName: "Duration",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: 'Less than 30 minutes',
                            value: 'LESS_THAN_30_MINUTES',
                            maxDuration: '1800s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 1 hour',
                            value: 'LESS_THAN_1_HOUR',
                            maxDuration: '3600s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 6 hours',
                            value: 'LESS_THAN_6_HOURS',
                            maxDuration: '21600s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 12 hours',
                            value: 'LESS_THAN_12_HOURS',
                            maxDuration: '43200s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 24 hours',
                            value: 'LESS_THAN_24_HOURS',
                            maxDuration: '86400s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'More than 24 hours',
                            value: 'MORE_THAN_24_HOURS',
                            maxDuration: null,
                            minDuration: '86400s'
                        },
                        {
                            displayValue: 'More than 48 hours',
                            value: 'MORE_THAN_48_HOURS',
                            maxDuration: null,
                            minDuration: '172800s'
                        },
                        {
                            displayValue: 'More than 72 hours',
                            value: 'MORE_THAN_72_HOURS',
                            maxDuration: null,
                            minDuration: '259200s'
                        },
                        {
                            displayValue: 'More than 1 week',
                            value: 'MORE_THAN_1_WEEK',
                            maxDuration: null,
                            minDuration: '604800s'
                        },
                        {
                            displayValue: 'More than 1 month',
                            value: 'MORE_THAN_1_MONTH',
                            maxDuration: null,
                            minDuration: '2592000s'
                        }

                    ],
                    optionValue: "MORE_THAN_24_HOURS"
                },
                {
                    displayName: "Acknowledged",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Has been acknowledged",
                            value: "HAS_ACKNOWLEDGEMENT"
                        },
                        {
                            displayValue: "Has not been acknowledged",
                            value: "NO_ACKNOWLEDGEMENT"
                        }
                    ],
                    optionValue: "HAS_ACKNOWLEDGEMENT"
                },
                {
                    displayName: "Corrective Action",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Has corrective action",
                            value: "HAS_CORRECTIVE_ACTION"
                        },
                        {
                            displayValue: "No corrective action",
                            value: "NO_CORRECTIVE_ACTION"
                        }
                    ],
                    optionValue: "HAS_CORRECTIVE_ACTION",
                },
                {
                    displayName: "Device Labels",
                    active: false,
                    dropdown: false,
                    options: [],
                    optionValue: "",
                    labels: [
                        ["", ""]
                    ]
                }
            )
        }
    }

    updatedFilters() {
        this.updateCount()
        // Check if any filter optionValues are null, if so, don't update
        // This is caused by the <select> element being reset when the element is removed from the DOM
        if (this.filters.filter(filter => filter.optionValue === null).length === 0) {
            this.updateFilteredAlerts()
        }
    }

    addFilterLabel(filter) {
        filter.labels.push(["", ""])
    }

    removeFilterLabel(filter, index) {
        filter.labels.splice(index, 1)
    }

    updateCount() {
        let count = 0
        Object.keys(this.filters).forEach(key => {
            if (this.filters[key].active) {
                count += 1
            }
        });
        this.filterCount = count
    }

    updateFilteredAlerts() {
        if (this.activeState === this.states.ACTIVE_ALERTS) {
            this.updateFilteredActiveAlerts()
        } else if (this.activeState === this.states.LOG) {
            this.updateFilteredAlertLog()
        }
    }

    updateFilteredActiveAlerts() {

        const params = this.paramsFromFilters()
        delete params.statuses
        this.AlertsAndRulesService.listActiveOrganizationAlerts(params).then(alerts => {
            alerts.forEach(alert => {
                alert.showRangeDropdown = false;
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.triggerDescription = alertTriggerDescription(alert);
            });
            this.filteredAlerts = alerts;
            this.projects.forEach(project => {
                project.alerts = this.filteredAlerts.filter(alert => alert.project === project.name)
                project.alerts.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
                project.loadedDevices = false
            });
    
            // Sort projects based on the number of alerts, then by ABC
            this.projects.sort((a, b) => {
                if (a.alerts.length > b.alerts.length) return -1
                if (a.alerts.length < b.alerts.length) return 1
                if (a.displayName < b.displayName) return -1
                if (a.displayName > b.displayName) return 1
                return 0
            });

            if (this.selectedProject !== null) { // On mobile the selected project can be null

                const deviceIds = [...new Set(this.selectedProject.alerts.map(alert => alert.device.split('/')[3]))];
    
                this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                    const devices = devicesResponse.devices;
                    this.selectedProject.alerts.forEach(alert => {
                        const device = devices.find(d => d.name === alert.device);
                        alert.device = device;
                    });
                    this.selectedProject.loadedDevices = true
                    this.$scope.$applyAsync();
                });
            }
    
            this.removePopup()
            this.updateMapActiveAlerts()
        });
        
    }

    updateFilteredAlertLog() {

        this.fetchAggregatedAlertsLog().then(() => {
            this.updateMapAlertLog()
            this.loadAlertLogForProject(this.selectedProject)
        })

    }


    clearFilters() {
        this.showFilters = false
        this.filters = []
        this.filterCount = 0
        this.filteredAlerts = this.alerts
        this.updateFilteredAlerts()
    }

    selectProject(project, scrollToElement = true) {
        this.selectedProject = project
        this.popup.remove();

        if (this.selectedProject === null) {
            // Can be reset to null when using on mobile to have a master detail type of view
            return
        }
        
        if (!project.loadedDevices) {
            const deviceIds = this.selectedProject.alerts.map(alert => alert.device.split('/')[3]);
            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                this.selectedProject.alerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.device = device;
                });
                if (this.selectedProject.name === project.name) {
                    this.selectedProject.loadedDevices = true    
                }
            });
        }

        // Check if project has a location
        if (project.location.latitude !== null && project.location.longitude !== null) {
            this.showPopup(project.id)
        } else {
            // Reset map
            this.popup.remove();
            this.marker.remove();
            this.map.flyTo({ center: [0, 35], zoom: 0.8 });
        }

        const elementInProjectList = document.getElementById(project.id)
        if (elementInProjectList && !this.isMobile && scrollToElement) {
            elementInProjectList.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }

        if (this.activeState === this.states.LOG) {
            this.loadAlertLogForProject(project)
        }
    }

    alertIcon(alert) {
        let iconName = alert.trigger.field
        if (iconName === 'relativeHumidity') {
            iconName = 'humidity'
        }
        if (iconName === 'connectionStatus') {
            iconName = alert.device.typeIcon
        }
        if (iconName === 'objectPresent') {
            return 'proximity'
        }
        if (iconName === 'waterPresent') {
            return 'waterDetector'
        }
        return iconName
    }

    getAlertStatusHeader(alert) {
        switch (alert.status) {
            case 'TRIGGERED':
                return 'Open - Action Needed'
            case 'ACKNOWLEDGED':
                const event = alert.events.find(e => e.type === 'ACKNOWLEDGED') // eslint-disable-line
                const acknowledgedBy = event.acknowledged.account.displayName || event.acknowledged.account.email // eslint-disable-line
                return `Acknowledged by ${acknowledgedBy}`
            case 'MONITORING':
                return 'Monitoring'
            case 'RESOLVED':
                return 'Resolved'
            case 'ARCHIVED':
                return 'Archived'
            default:
                return alert.status
        }
    }

    getAlertStatusDescription(alert) {
        switch (alert.status) {
            case 'TRIGGERED':
                return `${moment(alert.createTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(alert.updateTime).fromNow()})`
            case 'ACKNOWLEDGED':
                const event = alert.events.find(e => e.type === 'ACKNOWLEDGED') // eslint-disable-line
                return `${moment(event.createTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(event.createTime).fromNow()})`
            case 'MONITORING':
                return `${moment(alert.updateTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(alert.updateTime).fromNow()})`
            case 'RESOLVED':
                return `${moment(alert.resolveTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(alert.resolveTime).fromNow()})`
            case 'ARCHIVED':
                return `${moment(alert.archiveTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(alert.archiveTime).fromNow()})`
            default:
                return ''
        }
    }


    getDuration(alert, asMinutes = false) {
        // Extract the numeric value (seconds) from the string (eg. "3600s" -> 3600)
        const seconds = parseInt(alert.duration, 10);

        // Create a moment.duration object
        const duration = moment.duration(seconds, 'seconds');

        if (asMinutes) {
            return Math.round(duration.asMinutes());
        }
    
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
    
        // Not using moment.js .humanize() because is rounds heavily
        const parts = []; // Build the duration string parts
        
        if (days > 0) {
            parts.push(`${days} days`);
        }
        if (hours > 0) {
            if (hours === 1) {
                parts.push(`${hours} hr`);
            } else {
                parts.push(`${hours} hrs`);
            }
        }
        if (minutes > 0 && days === 0) { // Only show minutes if less than a day
            parts.push(`${minutes} min`);
        }
    
        // If there are no parts, then the duration is less than a minute
        if (parts.length === 0) {
            return '<1 min';
        }
    
        return parts.join(' ');
    }

    numberOfOpenAlertsForProject(project) {
        return project.alerts.filter(alert => alert.status === 'TRIGGERED').length;
    }

    numberOfAcknowledgedAlertsForProject(project) {
        return project.alerts.filter(alert => alert.status === 'ACKNOWLEDGED').length;
    }

    numberOfMonitoringAlertsForProject(project) {
        return project.alerts.filter(alert => alert.status === 'MONITORING').length;
    }

    getNumberOfUserInteractions(alert) {
        // Get the number of user interactions (acknowledgements, corrective actions, comments)
        return alert.events.filter(event => {
            return event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED || 
                   event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION || 
                   event.type === ALERT_EVENT_TYPES.COMMENT;
        }).length;
    }


    exitAlertOverview() {
        this.$state.go(States.SENSORS);
    }

    $onDestroy() {
        this.$rootScope.$broadcast(HIDE_ORGANIZATION_ALERTS);
    }

    setupMapListeners() {    
        // Initialize the popup if not already done
        if (!this.popup) {
            this.popup = new mapboxgl.Popup({ // eslint-disable-line
                offset: [0, -8],
                closeButton: false,
                closeOnClick: false
            });
        }
    
        // Initialize the marker if not already done
        if (!this.marker) {
            this.marker = new mapboxgl.Marker(); // eslint-disable-line
        }
    
        // Event handler for mouseenter on points
        const onMouseEnter = (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
            const coordinates = e.features[0].geometry.coordinates.slice();
    
            // Ensure the popup appears over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            const project = this.projects.find(p => p.id === e.features[0].properties.id);

            this.popup
                .setLngLat(coordinates)
                .setHTML(this.popupHtmlContent(project))
                .addTo(this.map);
        };
    
        // Event handler for mouseleave on points
        const onMouseLeave = () => {
            this.map.getCanvas().style.cursor = '';
            this.popup.remove();
        };
    
        // Event handler for click on points
        const onClickPoint = (e) => {
            const project = this.projects.find(p => p.id === e.features[0].properties.id);
            this.selectProject(project);
            this.$scope.$applyAsync();
        };
    
        // Bind the event handlers to the class instance to maintain context
        this.onMouseEnter = onMouseEnter.bind(this);
        this.onMouseLeave = onMouseLeave.bind(this);
        this.onClickPoint = onClickPoint.bind(this);
    
        // Attach event listeners to 'alert-points' layer
        this.map.on('mouseenter', 'alert-points', this.onMouseEnter);
        this.map.on('mouseleave', 'alert-points', this.onMouseLeave);
        this.map.on('click', 'alert-points', this.onClickPoint);

        // Attach event listeners to 'alert-log-points' layer
        this.map.on('mouseenter', 'alert-log-points', this.onMouseEnter);
        this.map.on('mouseleave', 'alert-log-points', this.onMouseLeave);
        this.map.on('click', 'alert-log-points', this.onClickPoint);
    
        // Attach event listeners to 'no-alert-points' layer
        this.map.on('mouseenter', 'no-alert-points', this.onMouseEnter);
        this.map.on('mouseleave', 'no-alert-points', this.onMouseLeave);
        this.map.on('click', 'no-alert-points', this.onClickPoint);
    
        // Handle click events on the map for editing location
        this.map.on('click', (e) => {
            if (!this.editingLocation) return;
    
            this.getTimeZone(e.lngLat);
    
            // Remove existing marker if it exists
            if (this.marker) {
                this.marker.remove();
            }
    
            // Place a new marker on the map
            this.marker = new mapboxgl.Marker() // eslint-disable-line
                .setLngLat([e.lngLat.lng, e.lngLat.lat])
                .setDraggable(false)
                .addTo(this.map);
        });
    
        // Add Mapbox GL Geocoder for search functionality
        if (!this.geocoder) {
            this.geocoder = new MapboxGeocoder({ // eslint-disable-line
                accessToken: mapboxgl.accessToken, // eslint-disable-line
                mapboxgl: mapboxgl, // eslint-disable-line
                marker: false  // Disable the default marker
            });
    
            this.map.addControl(this.geocoder);
    
            this.geocoder.on('result', (e) => {
                const lngLat = e.result.center;
                this.map.flyTo({ center: lngLat, zoom: 15 });
            });
        }
    }

    updateMapActiveAlerts() {
        // Create locations array
        const locations = [];
        this.filteredProjects.forEach(project => {
            if (project.location.latitude === null || project.location.longitude === null) return;
    
            const hasAlerts = project.alerts && project.alerts.length > 0;
    
            locations.push({
                coords: [project.location.longitude, project.location.latitude],
                name: project.displayName,
                description: `Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b>`,
                id: project.id,
                hasAlerts: hasAlerts,
                alertCount: project.alerts.length, // Added alertCount
                sortKey: hasAlerts ? 1 : 0
            });
        });
    
        // Create GeoJSON features
        this.allFeatures = locations.map(loc => ({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: loc.coords },
            properties: { 
                name: loc.name, 
                description: loc.description, 
                id: loc.id,
                hasAlerts: loc.hasAlerts,
                alertCount: loc.alertCount, // Added alertCount
                sortKey: loc.sortKey
            }
        }));
    
        const geojson = {
            type: 'FeatureCollection',
            features: this.allFeatures
        };
    
        // Remove existing layers and sources if they exist
        if (this.map.getSource('markers')) {
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            if (this.map.getLayer('alert-log-points')) {
                this.map.removeLayer('alert-log-points');
            }
            if (this.map.getLayer('alert-labels')) {
                this.map.removeLayer('alert-labels');
            }
            this.map.removeSource('markers');
        }
        this.map.addSource('markers', {
            type: 'geojson',
            data: geojson,
        });
    
        // Create the pulsing dot
        const size = 120;
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4), // 4 channels: R, G, B, A
    
            // onAdd method receives the map instance
            onAdd: function (map) {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
    
                this.map = map; // Assign the map instance to this.map
            },
    
            render: function () {
                const duration = 2600;
                const t = (performance.now() % duration) / duration;
    
                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.3 * t + radius;
                const context = this.context;
    
                // Clear canvas
                context.clearRect(0, 0, this.width, this.height);
    
                // Draw outer circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(220, 110, 93, ${1 - t})`;
                context.fill();
    
                // Draw inner circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(220, 110, 93, 1)';
                context.strokeStyle = '#fff';
                context.lineWidth = 3;
                context.fill();
                context.stroke();
    
                // Update the image data
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;
    
                // Trigger a repaint of the map
                this.map.triggerRepaint();
    
                // Return true to indicate that the image has been updated
                return true;
            }
        };
    
        // Add the pulsing dot image to the map
        if (!this.map.hasImage('pulsing-dot')) {
            this.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
        }
    
        // Add Layers
    
        // Add circle layer for non-alert points
        this.map.addLayer({
            id: 'no-alert-points',
            type: 'circle',
            source: 'markers',
            filter: [
                'all',
                ['!', ['has', 'point_count']],
                ['==', ['get', 'hasAlerts'], false]
            ],
            paint: {
                'circle-color': '#5F9B55',
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    4, 5,   // At zoom level 4, radius is 5
                    12, 8  // At zoom level 12, radius is 6
                ],
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
            }
        });
    
        // Add symbol layer for alert points
        this.map.addLayer({
            id: 'alert-points',
            type: 'symbol',
            source: 'markers',
            filter: [
                'all',
                ['!', ['has', 'point_count']],
                ['==', ['get', 'hasAlerts'], true]
            ],
            layout: {
                'icon-image': 'pulsing-dot',
                'icon-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    4, 0.8,   // At zoom level 4, radius is 0.8
                    10, 1.5  // At zoom level 12, radius is 1.5
                ],
                'icon-allow-overlap': true,
                'text-field': ['to-string', ['get', 'alertCount']], // Display alert count
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    4, 10,   // At zoom level 4, size is 10
                    10, 12  // At zoom level 10, size is 12
                ],
                'text-anchor': 'center',
                'text-offset': [-0.04, 0],
                'text-allow-overlap': true,
                'text-ignore-placement': true
            },
            paint: {
                'text-color': '#FFFFFF',
            }
        });
    
        // Initialize the popup
        if (!this.popup) {
            this.popup = new mapboxgl.Popup({ // eslint-disable-line
                offset: [0, -8],
                closeButton: false,
                closeOnClick: false
            });
        }

        this.sortProjectsActiveAlerts()
    
        this.$scope.$applyAsync();
    }

    updateMapAlertLog() {

        this.removePopup()

        // Remove existing layers and sources if they exist
        if (this.map.getSource('markers')) {
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('alert-log-points')) {
                this.map.removeLayer('alert-log-points');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            if (this.map.getLayer('alert-labels')) {
                this.map.removeLayer('alert-labels');
            }
            this.map.removeSource('markers');
        }

        // Create locations array
        const locations = [];
        this.filteredProjects.forEach(project => {
            if (project.location.latitude === null || project.location.longitude === null) return;

            // Add a point for each project with the number of alerts shown on each
            locations.push({
                coords: [project.location.longitude, project.location.latitude],
                name: project.displayName,
                description: `Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b>`,
                id: project.id,
                alertCount: project.totalAggregatedAlertCount || 0,
                sortKey: project.totalAggregatedAlertCount || 0
            });
        });

        // Create GeoJSON features
        this.allFeatures = locations.map(loc => ({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: loc.coords },
            properties: { 
                name: loc.name, 
                description: loc.description, 
                id: loc.id,
                alertCount: loc.alertCount,
                sortKey: loc.sortKey
            }
        }));

        const geojson = {
            type: 'FeatureCollection',
            features: this.allFeatures
        };

        this.map.addSource('markers', {
            type: 'geojson',
            data: geojson,
        });

        // Add Layers, all look the same. There is no need to differentiate between alert and non-alert points

        // Add circle first, then text on top

        const maxAlertCount = Math.max(...locations.map(loc => loc.alertCount));
        // Add circle layer for points
        this.map.addLayer({
            id: 'alert-log-points',
            type: 'circle',
            source: 'markers',
            layout: {
                'circle-sort-key': ['get', 'sortKey'] 
            },
            paint: {
                
                'circle-color': [
                    'interpolate',
                    ['exponential', 0.5],  // Use 0.5 for logarithmic scaling
                    ['get', 'alertCount'],
                    0, '#A8A8A8',
                    1, '#F0AD4E', 
                    Math.max(maxAlertCount / 2, 2), '#E68349',
                    Math.max(maxAlertCount, 3), '#D9534F'
                ],

                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    // If alertCount is greater than 99 set radius to 12
                    4, ['case', ['>', ['get', 'alertCount'], 99], 12, 8],
                    12, 14  // At zoom level 12, radius is 14
                ],
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
            },
        });

        this.map.addLayer({
            id: 'alert-labels',
            type: 'symbol',
            source: 'markers',
            layout: {
                'icon-allow-overlap': true,
                'text-field': ['to-string', ['get', 'alertCount']], // Display alert count
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    4, 10,   // At zoom level 4, size is 10
                    10, 13  // At zoom level 10, size is 12
                ],
                'text-anchor': 'center',
                'text-offset': [-0.04, 0],
                'text-allow-overlap': true,
                'text-ignore-placement': true,
                'symbol-sort-key': ['get', 'sortKey']
            },
            paint: {
                'text-color': '#FFFFFF',
            }
        });

        // Initialize the popup
        if (!this.popup) {
            this.popup = new mapboxgl.Popup({ // eslint-disable-line
                offset: [0, -8],
                closeButton: false,
                closeOnClick: false
            });
        }

        this.$scope.$applyAsync();
        
    }
    
    queryChanged() {
        if (this.query === '') {
            this.filteredProjects = this.projects;
            if (this.activeState === this.states.ACTIVE_ALERTS) {
                this.updateMapActiveAlerts()
            } else if (this.activeState === this.states.LOG) {
                this.updateMapAlertLog()
            }
            return;
        }
        this.filteredProjects = this.projects.filter(project => project.displayName.toLowerCase().includes(this.query.toLowerCase()));
        if (this.activeState === this.states.ACTIVE_ALERTS) {
            this.updateMapActiveAlerts()
        } else if (this.activeState === this.states.LOG) {
            this.updateMapAlertLog()
        }
    }

    clearQuery() {
        this.query = '';
        this.queryChanged();
    }

    showPopup(projectId) {
        // Trigger popup based on project name
        const feature = this.allFeatures.find(f => f.properties.id === projectId);
        if (feature) {
            this.map.flyTo({
                duration: 800,
                center: feature.geometry.coordinates,
                zoom: this.map.getZoom() > 4.5 ? this.map.getZoom() : 4.5
            });
            const project = this.projects.find(p => p.id === projectId);
            setTimeout(() => {
                this.popup
                    .setLngLat(feature.geometry.coordinates)
                    .setHTML(this.popupHtmlContent(project))
                    .addTo(this.map);   
            }, 400);
        }
    }

    // Generate dynamic HTML content for the popup
    popupHtmlContent(project) {

        if (this.activeState === this.states.ACTIVE_ALERTS) {
            const openAlertsHtml = this.numberOfOpenAlertsForProject(project) === 0 ? '' :
                `<span>
                    <svg width="12px" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82193 1.31733L10.3439 8.36057C10.4566 8.586 10.5099 8.83643 10.4985 9.08822C10.4872 9.34 10.4117 9.58471 10.2791 9.79907C10.1466 10.0134 9.9615 10.1904 9.74136 10.3131C9.52121 10.4357 9.27336 10.5001 9.02129 10.5H1.97806C1.72609 10.5 1.4783 10.4356 1.25823 10.3129C1.03816 10.1901 0.853121 10.0131 0.720671 9.79879C0.588228 9.58443 0.512778 9.33979 0.501485 9.08807C0.490193 8.83636 0.543435 8.58593 0.656157 8.36057L4.1775 1.31733C4.30021 1.07174 4.48893 0.865185 4.7225 0.720828C4.956 0.576464 5.22514 0.5 5.49971 0.5C5.77421 0.5 6.04336 0.576464 6.27693 0.720828C6.51043 0.865185 6.69914 1.07174 6.82193 1.31733ZM5.5 7.06243C5.25136 7.06243 5.01293 7.16122 4.83707 7.337C4.66129 7.51286 4.5625 7.75129 4.5625 7.99993C4.5625 8.24857 4.66129 8.48707 4.83707 8.66286C5.01293 8.83872 5.25136 8.93743 5.5 8.93743C5.74864 8.93743 5.98707 8.83872 6.16293 8.66286C6.33871 8.48707 6.4375 8.24857 6.4375 7.99993C6.4375 7.75129 6.33871 7.51286 6.16293 7.337C5.98707 7.16122 5.74864 7.06243 5.5 7.06243ZM5.5 6.12493C5.845 6.12493 6.125 5.92493 6.125 5.67865V3.44611C6.125 3.19986 5.845 2.99986 5.5 2.99986C5.155 2.99986 4.875 3.19986 4.875 3.44611V5.67865C4.875 5.92493 5.155 6.12493 5.5 6.12493Z" fill="#DC724A" style="fill:#DC724A;fill:color(display-p3 0.8627 0.4471 0.2902);fill-opacity:1;"></path>
                    </svg>
                    ${this.numberOfOpenAlertsForProject(project)} Open
                </span>`
    
            const acknowledgeAlertsHtml = this.numberOfAcknowledgedAlertsForProject(project) === 0 ? '' :
                `<span>
                    <svg width="12px" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.3125 6C0.3125 2.85875 2.85875 0.3125 6 0.3125C9.14125 0.3125 11.6875 2.85875 11.6875 6C11.6875 9.14125 9.14125 11.6875 6 11.6875C2.85875 11.6875 0.3125 9.14125 0.3125 6ZM8.10583 4.94183C8.14083 4.8952 8.16616 4.84203 8.18032 4.78547C8.19448 4.72891 8.19719 4.67008 8.18829 4.61246C8.17939 4.55483 8.15906 4.49956 8.1285 4.44991C8.09793 4.40025 8.05775 4.3572 8.01032 4.3233C7.96288 4.28939 7.90914 4.26531 7.85226 4.25247C7.79539 4.23963 7.73652 4.23828 7.67911 4.24852C7.62171 4.25876 7.56693 4.28036 7.518 4.31207C7.46906 4.34378 7.42696 4.38495 7.39417 4.43317L5.5065 7.07567L4.55917 6.12833C4.47623 6.05105 4.36654 6.00898 4.2532 6.01098C4.13985 6.01298 4.03171 6.0589 3.95155 6.13905C3.8714 6.21921 3.82548 6.32735 3.82348 6.4407C3.82148 6.55404 3.86355 6.66373 3.94083 6.74667L5.25333 8.05917C5.29824 8.10404 5.35238 8.13861 5.41199 8.16046C5.4716 8.18231 5.53525 8.19093 5.59853 8.18571C5.6618 8.18049 5.72318 8.16157 5.77841 8.13025C5.83363 8.09893 5.88138 8.05596 5.91833 8.00433L8.10583 4.94183Z" fill="#EAC25D" style="fill:#EAC25D;fill:color(display-p3 0.9157 0.7593 0.3635);fill-opacity:1;"></path>
                    </svg>
                    ${this.numberOfAcknowledgedAlertsForProject(project)} Ack
                </span>`
    
            const monitoringAlertsHtml = this.numberOfMonitoringAlertsForProject(project) === 0 ? '' :
                `<span>
                    <svg width="12px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <g clip-path="url(#clip0_3165_5461_cache4)">
                        <path d="M0.566406 3.97261C0.566406 2.33088 1.89729 1 3.53902 1H11.5938" fill="#73A6DF" style="fill:#73A6DF;fill:color(display-p3 0.4510 0.6510 0.8745);fill-opacity:1;"></path>
                        <path d="M3.53902 1C1.89729 1 0.566406 2.33088 0.566406 3.97261V10.4819C0.566406 12.1237 1.89729 13.4545 3.53902 13.4545H11.5938C13.2355 13.4545 14.5664 12.1237 14.5664 10.4819V3.97261C14.5664 2.33088 13.2355 1 11.5938 1H3.53902Z" fill="#73A6DF" style="fill:#73A6DF;fill:color(display-p3 0.4510 0.6510 0.8745);fill-opacity:1;"></path>
                        <path d="M3.75391 8.63632L6.29936 6.09086L8.12191 7.91341C8.9782 6.6842 10.0939 5.65799 11.3903 4.90723" stroke="white" style="stroke:white;stroke-opacity:1;" stroke-width="1.30052" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                        <defs>
                        <clipPath id="clip0_3165_5461_cache4">
                        <rect width="14" height="14" fill="white" style="fill:white;fill-opacity:1;" transform="translate(0.566406 0.219727)"></rect>
                        </clipPath>
                        </defs>
                    </svg>
                    ${this.numberOfMonitoringAlertsForProject(project)} Monitoring
                </span>`
    
            const noAlerts = project.alerts.length > 0 ? '' : 
                `<span>
                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.996094 4.49805L3.62109 7.12305L7.99609 0.998047" style="stroke:rgb(53, 99, 44);stroke-opacity:1;" stroke-width="1.68845" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                                                                 
                    <span style="margin-left: 3px;">No Alerts</span>
                </span>`
    
            const noAlertsClass = project.alerts.length > 0 ? '' : 'no-alerts'
    
            return `
                    <div class="popup-content">
                        <div class="popup-title">${project.displayName}</div>
                        <div class="popup-description">Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b></div>
                        <div style="border-top: 1px solid rgba(100, 100, 100, 0.1); padding-top: 12px; margin-top: 12px;">
                            <div class="alert-summary ${noAlertsClass}" style="justify-content: center;">
                                ${openAlertsHtml}
                                ${acknowledgeAlertsHtml}
                                ${monitoringAlertsHtml}
                                ${noAlerts}
                            </div>
                        </div>
                    </div>
                `
        } else if (this.activeState === this.states.LOG) {
            return `
                <div class="popup-content">
                    <div class="popup-title">${project.displayName}</div>
                    <div class="popup-description">Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b></div>
                    <div style="border-top: 1px solid rgba(100, 100, 100, 0.1); padding-top: 12px; margin-top: 12px;">
                        <span>${this.getFormattedDuration(this.dateRange)}: <b>${project.totalAggregatedAlertCount} Alert${project.totalAggregatedAlertCount === 1 ? '' : 's'}</b></span>
                    </div>
                </div>
            `
        }

        return '';
    }

    removePopup() {
        if (this.popup) {
            this.popup.remove();
        }
    }

    getTimeZone(lngLat) {
        const lat = lngLat.lat;
        const lng = lngLat.lng;
        this.readyToSaveLocation = false
        this.loadingTimeZone = true
        this.$scope.$applyAsync()
  
        // Use the Mapbox Tilequery API to get the time zone information
        fetch(`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`) // eslint-disable-line
            .then(response => response.json())
            .then(data => {
                if (data && data.features && data.features.length > 0) {
                    const timeZoneFeature = data.features[0];
                    const timeZoneId = timeZoneFeature.properties.TZID;
                    this.timeZoneToBeConfirmed = timeZoneId
                    this.readyToSaveLocation = true
                } else {
                    this.marker.remove();
                    this.ToastService.showSimpleTranslated('project_no_valid_timezone_found');
                }
            })
            .catch(error => {
                console.error('Error fetching time zone:', error); // eslint-disable-line no-console
                this.ToastService.showSimpleTranslated('project_timezone_wasnt_updated');
            }).finally(() => {
                this.loadingTimeZone = false
                this.$rootScope.$applyAsync()
            })
    }

    editLocation(project) {
        // Make map ready for user to place a marker
        this.editingLocation = true;
        this.projectBeingEdited = project;
    
        // Ensure 'alert-points' is above 'no-alert-points'
        if (this.map.getLayer('alert-points')) {
            this.map.moveLayer('alert-points');
        }
    
        // Use the unique project ID to set opacity
        const projectId = project.id;
    
        // For 'alert-points' layer (symbol layer)
        if (this.map.getLayer('alert-points')) {
            this.map.setPaintProperty('alert-points', 'icon-opacity', [
                'case',
                ['==', ['get', 'id'], projectId], 0.5,
                1
            ]);
        }

        // For 'alert-log-points' layer (circle layer)
        if (this.map.getLayer('alert-log-points')) {
            this.map.setPaintProperty('alert-log-points', 'circle-opacity', [
                'case',
                ['==', ['get', 'id'], projectId], 0.5,
                1
            ]);
        }
    
        // For 'no-alert-points' layer (circle layer)
        if (this.map.getLayer('no-alert-points')) {
            this.map.setPaintProperty('no-alert-points', 'circle-opacity', [
                'case',
                ['==', ['get', 'id'], projectId], 0.5,
                1
            ]);
        }
    
        this.removePopup();
        this.readyToSaveLocation = false;
        this.showConfirmLocationDialog = true;
        this.$scope.$applyAsync();
    }
    

    confirmNewLocation() {
        // Save the new location
        const newLocation = this.marker.getLngLat();
        this.IAMService.updateProject(this.projectBeingEdited.name, { 
            location: { 
                timeLocation: this.timeZoneToBeConfirmed, 
                latitude: newLocation.lat, 
                longitude: newLocation.lng
            }
        }).then((updatedProject) => {
            // Replace the project in the projects array
            const index = this.projects.findIndex(p => p.id === this.projectBeingEdited.id);
            this.projects[index].location = updatedProject.location;
            this.filteredProjects = this.projects;
            this.queryChanged(); // Update in case there is an active query entered
    
            // Remove layers and source from map
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('alert-log-points')) {
                this.map.removeLayer('alert-log-points');
            }
            if (this.map.getLayer('alert-labels')) {
                this.map.removeLayer('alert-labels');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            if (this.map.getSource('markers')) {
                this.map.removeSource('markers');
            }
            if (this.marker) {
                this.marker.remove();
            }
    
            // Update the map with the new data
            if (this.activeState === this.states.ACTIVE_ALERTS) {
                this.updateMapActiveAlerts();
            } else if (this.activeState === this.states.LOG) {
                this.updateMapAlertLog();
            }
    
            // Reset editing state
            this.editingLocation = false;
            this.projectBeingEdited = null;
            this.showConfirmLocationDialog = false;
    
            // Update time zone for the current project if it was the one being edited
            if (updatedProject.name === this.ProjectManager.currentProject.name) {
                this.ProjectManager.setCurrentProject(updatedProject);
                this.updateTimeZoneForCurrentProject(this.timeZoneToBeConfirmed);
            }
    
        }).catch(serverError => {
            console.error('Error updating project location', serverError); // eslint-disable-line no-console
        });
    }
    

    cancelLocationEdit() {
        this.editingLocation = false;
        this.projectBeingEdited = null;
    
        // Remove the editing marker if it exists
        if (this.marker) {
            this.marker.remove();
        }
    
        // Reset opacity for all markers
        if (this.map.getLayer('alert-points')) {
            this.map.setPaintProperty('alert-points', 'icon-opacity', 1);
        }
        if (this.map.getLayer('alert-log-points')) {
            this.map.setPaintProperty('alert-log-points', 'circle-opacity', 1);
        }
        if (this.map.getLayer('no-alert-points')) {
            this.map.setPaintProperty('no-alert-points', 'circle-opacity', 1);
        }
    
        // Ensure 'alert-points' is above 'no-alert-points'
        if (this.map.getLayer('alert-points') && this.map.getLayer('no-alert-points')) {
            this.map.moveLayer('alert-points');
        }
    
        // Set visibility for layers
        if (this.map.getLayer('alert-points')) {
            this.map.setLayoutProperty('alert-points', 'visibility', 'visible');
        }
        if (this.map.getLayer('alert-log-points')) {
            this.map.setLayoutProperty('alert-log-points', 'visibility', 'visible');
        }
        if (this.map.getLayer('no-alert-points')) {
            this.map.setLayoutProperty('no-alert-points', 'visibility', 'visible');
        }
        if (this.map.getLayer('clusters')) {
            this.map.setLayoutProperty('clusters', 'visibility', 'visible');
        }
        if (this.map.getLayer('cluster-count')) {
            this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
        }
    
        this.showConfirmLocationDialog = false;
    }
    

    clearLocation() {
        this.IAMService.updateProject(this.projectBeingEdited.name, { 
            location: { 
                timeLocation: "", 
                latitude: null, 
                longitude: null
            }
        }).then((updatedProject) => {
            this.removePopup();
    
            // Replace the project in the projects array
            const index = this.projects.findIndex(p => p.id === this.projectBeingEdited.id);
            this.projects[index].location = updatedProject.location;
            this.filteredProjects = this.projects;
            this.queryChanged(); // Update in case there is an active query entered
    
            // Remove layers and source from the map
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('alert-log-points')) {
                this.map.removeLayer('alert-log-points');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            if (this.map.getLayer('clusters')) {
                this.map.removeLayer('clusters');
            }
            if (this.map.getLayer('cluster-count')) {
                this.map.removeLayer('cluster-count');
            }
            if (this.map.getSource('markers')) {
                this.map.removeSource('markers');
            }
    
            // Update the map with the new data
            this.updateMapActiveAlerts();
            this.editingLocation = false;
            this.projectBeingEdited = null;
            this.showConfirmLocationDialog = false;
    
            // Update time zone for the current project if it was the one being edited
            if (updatedProject.name === this.ProjectManager.currentProject.name) {
                this.ProjectManager.setCurrentProject(updatedProject);
                this.updateTimeZoneForCurrentProject("");
            }
        }).catch(serverError => {
            console.error('Error updating project location', serverError); // eslint-disable-line no-console
        });
    }

    updateTimeZoneForCurrentProject(timeZone) {
        moment.tz.setDefault(timeZone); // Update Moment timezone globally for Studio
        if (window.Highcharts) {
            window.Highcharts.setOptions({
                time: {
                    useUTC: true,
                    timezone: timeZone
                }
            })
        }

        this.$rootScope.$applyAsync();
        this.$rootScope.$broadcast('TIME_ZONE_CHANGED', { timeZone: timeZone });
    }
    
    openProject(projectId) {
        this.$state.go(States.SENSORS, {
            projectId: projectId
        });
    }

    fetchAllProjects() {
        const deferred = this.$q.defer();
        this.getProjectsPage(null, [])
            .then(projects => deferred.resolve(projects))
            .catch(() => {
                this.ToastService.showSimpleTranslated('projects_wasnt_fetched');
                deferred.reject();
            });
        return deferred.promise;
    }

    acknowledgedByUser(alert) {
        const acknowledgedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
        return acknowledgedEvent ? acknowledgedEvent.acknowledged.account.displayName : '';
    }

    correctiveActionByUser(alert) {
        const correctiveActionEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
        return correctiveActionEvent ? correctiveActionEvent.correctiveAction.account.displayName : '';
    }

    exportAlertsForSelectedProject() {
         
        // Creates a CSV based on the alerts and downloads it automatically 
        let csv = 'Alert Name,Alert ID,Alert Trigger,Alert Status,Device Name,Device ID,Create Time,Resolve Time,Duration,Duration (minutes),Has Been Acknowledged,Acknowledged by,Has Corrective Action,Corrective Action by\r\n';

        const alerts = this.activeState === this.states.ACTIVE_ALERTS ? this.selectedProject.alerts : this.selectedProject.alertLog;


        alerts.forEach(alert => {
            const acknowledgedBy = this.acknowledgedByUser(alert);
            const correctiveActionBy = this.correctiveActionByUser(alert);
            const durationMinutes = this.getDuration(alert, true);
            const durationReadable = this.getDuration(alert);
            const deviceName = alert.device?.labels?.name || 'Device removed from project';
            csv += `${alert.displayName},${alert.id},${alert.triggerDescription},${alert.status},${deviceName},${alert.device?.id || ''},${alert.createTime},${alert.resolveTime || ''},${durationReadable},${durationMinutes},${alert.hasBeenAcknowledged},${acknowledgedBy},${alert.hasCorrectiveAction},${correctiveActionBy}\r\n`;
        });

        const exportedFilename = 'alert_log.csv'
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        this.ToastService.showSimpleTranslated('alert_log_exported');
    }

    getProjectsPage(pageToken, previousData) {
        const requestParams = {
            organization: this.organization,
            pageToken
        }
        return this.IAMService
            .projects(requestParams)
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getProjectsPage(nextPageToken, allData);
                }
                return allData;
            });
    }

    truncatedProjectDisplayName(displayName) {
        return truncateString(displayName, 30);
    }

    showTimeline(alert) {
        this.DialogService.show({
            controller: TimelineController,
            template: TimelineTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                alert
            }
        })
    }

    goToDevice(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3]
        });
    }

    goToAlertRule(alert) {
        this.$state.go(States.ALERTS_RULES_DETAIL, {
            projectId: alert.project.split('/')[1],
            ruleId: alert.rule.split('/')[3]
        });
    }

    archiveAlert(alert) {
        this.AlertsAndRulesService.archiveAlert(alert)
            .then(() => {
                this.$state.reload()
            });
    }

    getFormattedDuration(duration) {
        switch (duration) {
            case 'CUSTOM':
                return 'Custom'
            case 'TODAY':
                return 'Today'
            case 'YESTERDAY':
                return 'Yesterday'
            case 'LAST_3_DAYS':
                return 'Last 3 days'
            case 'LAST_7_DAYS':
                return 'Last 7 days'
            case 'LAST_30_DAYS':
                return 'Last 30 days'
            case 'LAST_3_MONTHS':
                return 'Last 3 months'
            case 'LAST_6_MONTHS':
                return 'Last 6 months'
            case 'LAST_12_MONTHS':
                return 'Last 12 months'
            case 'LAST_3_YEARS':
                return 'Last 3 years'
            case 'THIS_WEEK':
                return 'This week'
            case 'LAST_WEEK':
                return 'Last week'
            default:
                return duration
        }
    }

    hideRangeOptionsDropdown() {
        this.showRangeDropdown = false
    }

    updateRange(range) {
        this.dateRange = range
        this.hideRangeOptionsDropdown()
        switch (this.dateRange) {
            case 'TODAY':
                this.startTime = moment().startOf('day') // Start of today
                this.endTime = moment().add(1, 'day').startOf('day') // Start of tomorrow
                break
            case 'YESTERDAY':
                this.startTime = moment().subtract(1, 'day').startOf('day') // Start of yesterday
                this.endTime = moment().startOf('day') // Start of today
                break
            case 'THIS_WEEK':
                this.startTime = moment().startOf('isoWeek')
                this.endTime = moment()
                break
            case 'LAST_WEEK':
                this.startTime = moment().subtract(1, 'week').startOf('isoWeek')
                this.endTime = moment().subtract(1, 'week').endOf('isoWeek')
                break
            case 'LAST_3_DAYS':
                this.startTime = moment().subtract(3, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_7_DAYS':
                this.startTime = moment().subtract(7, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_30_DAYS':
                this.startTime = moment().subtract(30, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_3_MONTHS':
                this.startTime = moment().subtract(3, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_6_MONTHS':
                this.startTime = moment().subtract(6, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_12_MONTHS':
                this.startTime = moment().subtract(12, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_3_YEARS':
                this.startTime = moment().subtract(3, 'year').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            default:
                this.dateRange = 'LAST_7_DAYS'
                this.startTime = moment().subtract(7, 'day').startOf('day')
                this.endTime = moment()
                break
        }

        // Update the aggregated stats
        if (this.activeState === this.states.LOG) {
            this.fetchAggregatedAlertsLog().then(() => {
                this.updateMapAlertLog()
                this.loadAlertLogForProject(this.selectedProject)
            })
        }
    }

    showExtendedStorageInfo() {
        this.AnalyticsService.trackEvent("exports.extended_storage_info_opened")

        this.DialogService.show({
            controller: ExtendedStorageInfoController,
            controllerAs: '$ctrl',
            template: ExtendedStorageInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
        })
    }
}