
import moment from 'moment';
import { getHoursMinutesFormat, alertTriggerDescription, ruleFields } from 'services/utils';
import { RANGE_LABEL_DAY, SENSOR_GRAPH_RANGES, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { States } from '../../../app.router';
import { getStatusMessage } from 'services/SensorHelper';
import _cloneDeep from 'lodash/cloneDeep';
import Highcharts from 'highcharts';

import TimelineController from './timeline/timeline.controller';
import TimelineTemplate from './timeline/timeline.html';

import FilterController from './alert-log-filter-popover/controller';
import FilterTemplate from './alert-log-filter-popover/template.html';

import ExtendedStorageInfoController from '../../../common/extended-storage-info/controller';
import ExtendedStorageInfoTemplate from '../../../common/extended-storage-info/template.html';

/* @ngInject */
export default class ActiveAlertsController {
    
    constructor($scope, $state, $mdPanel, AlertsAndRulesService, SensorService, FeatureFlags, UserPreferencesManager, DialogService, ToastService, ProjectManager, AnalyticsService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$mdPanel = $mdPanel;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.AnalyticsService = AnalyticsService;

        this.updateFilters = this.updateFilters.bind(this)
        this.updateFilterCount = this.updateFilterCount.bind(this)
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }
    
    $onInit() {
        this.fields = ruleFields;
        this.ranges = SENSOR_GRAPH_RANGES;
        this.loadedAlerts = false;
        this.searching = false;
        this.alerts = [];
        this.alertCountLast90Days = 0;
        this.filters = [];
        this.singleRuleFilter = null;
        this.filterCount = 0;
        this.query = '';

        this.dateRange = 'LAST_30_DAYS'
        this.extendedStorageDurations = ["LAST_3_MONTHS", "LAST_6_MONTHS", "LAST_12_MONTHS", "LAST_3_YEARS"]
        this.startTime = moment().subtract(30, 'day').startOf('day')
        this.endTime = moment()
    
        // Create an array of the last 90 days regardless if the org has extended storage or not
        // The first 60 days will be gray if the org does not have extended storage
        this.days = Array.from({ length: 90 }, (_, i) => {
            return {
                alerts: [],
                date: moment().subtract(i, 'days'),
                dateFormatted: moment().subtract(i, 'days').format('MMM D, YYYY')
            };
        });
        this.days.reverse(); // Reverse the array so the most recent day is first

        
        // Fetch alerts for the timeline, 90 or 30 days depending on if the org has extended storage
        const timelineAlerts = []; 
        const fetchAlertsForTimeline = (pageToken = '') => {
            const params = {
                projects: [`projects/${this.ProjectManager.currentProjectId}`],
                includeEvents: true,
                startTime: moment().subtract(this.hasLongTermStorageFeatureFlag ? 90 : 30, 'day').startOf('day').toISOString(),
                endTime: moment().toISOString(),
                pageToken
            }
            return this.AlertsAndRulesService.listAlerts(params).then(response => {
                const alerts = response.data;
                timelineAlerts.push(...alerts);

                if (response.nextPageToken) {
                    // More pages to fetch
                    return fetchAlertsForTimeline(response.nextPageToken);
                }

                return Promise.resolve();
            });
        };

        fetchAlertsForTimeline().then(() => {
            timelineAlerts.forEach(alert => {
                alert.createTimeFormatted = moment(alert.createTime).format(getHoursMinutesFormat());
                // Check if createTime matches one of the days in the last 90 days array
                const alertDate = moment(alert.createTime).format('YYYY-MM-DD');
                const day = this.days.find(d => d.date.format('YYYY-MM-DD') === alertDate);
                if (day) {
                    day.alerts.unshift(alert); // Add alert to the beginning of the day's alerts array
                }
            })
            this.days.forEach(day => {
                // Sort alerts by createTime
                day.alerts.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
            })
            this.alertCountLast90Days = timelineAlerts.length;
        })
        
        this.searchAlertLog() 
    }

    filterOnSingleRule(ruleName) {
        this.singleRuleFilter = ruleName;
        this.groupedAlertsByDay = this.groupAlertsByDay();
    }

    resetSingleRuleFilter() {
        this.singleRuleFilter = null;
        this.groupedAlertsByDay = this.groupAlertsByDay();
    }

    groupAlertsByDay() {
        // Group alerts by day
        const groupedAlerts = [];
        const now = moment();
        this.alerts.forEach(alert => {

            if (this.singleRuleFilter && alert.rule !== this.singleRuleFilter) {
                return;
            }
            
            let alertDate = moment(alert.createTime)
            if (now.diff(alertDate, 'days') <= 6) {
                alertDate = alertDate.calendar(null, {
                    sameDay: `[Today], MMMM D, YYYY`,
                    lastDay: `[Yesterday], MMMM D, YYYY`,
                    lastWeek: 'dddd, MMMM D, YYYY',
                    sameElse: 'dddd, MMMM D, YYYY'
                });
            } else {
                alertDate = moment(alert.createTime).format('dddd, MMMM D, YYYY');
            }

            const day = groupedAlerts.find(d => d.date === alertDate);
            if (day) {
                day.alerts.push(alert);
            } else {
                groupedAlerts.push({
                    date: alertDate,
                    alerts: [alert]
                });
            }
        });

        // Sort grouped alerts by date
        groupedAlerts.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        })
        return groupedAlerts;
    }

    groupAlertsByRule() {
        const groupedAlerts = {}
        this.alerts.forEach(alert => {
            const alertRule = alert.rule;
            const alertDisplayName = alert.displayName;
            if (groupedAlerts[alertRule]) {
                groupedAlerts[alertRule].alerts.push(alert);
            } else {
                groupedAlerts[alertRule] = {
                    displayName: alertDisplayName,
                    alerts: [alert],
                    ruleName: alertRule
                }
            }            
        })
        // Sort by alerts count, descending
        return Object.values(groupedAlerts).sort((a, b) => {
            return b.alerts.length - a.alerts.length;
        })
    }

    dateIsInTheLast30Days(date) {
        return moment().diff(date, 'days') <= 30;
    }

    getFormattedDuration(duration) {
        switch (duration) {
            case 'CUSTOM':
                return 'Custom'
            case 'TODAY':
                return 'Today'
            case 'YESTERDAY':
                return 'Yesterday'
            case 'LAST_3_DAYS':
                return 'Last 3 days'
            case 'LAST_7_DAYS':
                return 'Last 7 days'
            case 'LAST_30_DAYS':
                return 'Last 30 days'
            case 'LAST_3_MONTHS':
                return 'Last 3 months'
            case 'LAST_6_MONTHS':
                return 'Last 6 months'
            case 'LAST_12_MONTHS':
                return 'Last 12 months'
            case 'LAST_3_YEARS':
                return 'Last 3 years'
            case 'THIS_WEEK':
                return 'This week'
            case 'LAST_WEEK':
                return 'Last week'
            default:
                return duration
        }
    }

    statusMessageForDevice(device) {
        return getStatusMessage(device);
    }

    isValueBasedSensor(device) {
        return device.type === 'temperature' || device.type === 'humidity' || device.type === 'co2';
    }

    hideRangeOptionsDropdown() {
        this.showRangeDropdown = false
    }

    updateRange(range) {
        this.dateRange = range
        this.hideRangeOptionsDropdown()
        switch (this.dateRange) {
            case 'TODAY':
                this.startTime = moment().startOf('day') // Start of today
                this.endTime = moment().add(1, 'day').startOf('day') // Start of tomorrow
                break
            case 'YESTERDAY':
                this.startTime = moment().subtract(1, 'day').startOf('day') // Start of yesterday
                this.endTime = moment().startOf('day') // Start of today
                break
            case 'THIS_WEEK':
                this.startTime = moment().startOf('isoWeek')
                this.endTime = moment()
                break
            case 'LAST_WEEK':
                this.startTime = moment().subtract(1, 'week').startOf('isoWeek')
                this.endTime = moment().subtract(1, 'week').endOf('isoWeek')
                break
            case 'LAST_3_DAYS':
                this.startTime = moment().subtract(3, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_7_DAYS':
                this.startTime = moment().subtract(7, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_30_DAYS':
                this.startTime = moment().subtract(30, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_3_MONTHS':
                this.startTime = moment().subtract(3, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_6_MONTHS':
                this.startTime = moment().subtract(6, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_12_MONTHS':
                this.startTime = moment().subtract(12, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_3_YEARS':
                this.startTime = moment().subtract(3, 'year').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            default:
                this.dateRange = 'LAST_7_DAYS'
                this.startTime = moment().subtract(7, 'day').startOf('day')
                this.endTime = moment()
                break
        }

        this.searchAlertLog()
    }

    showExtendedStorageInfo() {
        this.AnalyticsService.trackEvent("exports.extended_storage_info_opened")

        this.DialogService.show({
            controller: ExtendedStorageInfoController,
            controllerAs: '$ctrl',
            template: ExtendedStorageInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
        })
    }

    // Highcharts initialization
    initializeChart(id, displayName, data) {
          

        Highcharts.chart(id, {
            chart: {
                type: 'line',
                height: 74,
                marginLeft: 44,
                marginBottom: 30,
                reflow: true
                              
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 30 * 24 * 3600 * 1000, // One tick per month
                labels: {
                    format: '{value:%b}' // Display short month name
                },
                tickLength: 0,
                lineWidth: 0
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value}'
                },
                min: 0,
                max: Math.max(...data) + 5, // Adjust max dynamically based on data
                tickInterval: Math.ceil(Math.max(...data) / 3),
                
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                followPointer: true,
                outside: true,
                shadow: false,
                animation: false,
                hideDelay: 100,
                borderRadius: 12,
                padding: 10,
                shared: true,
                useHTML: true,
                formatter() {
                    const momentFormat = `dddd, MMM D`;
                    const timestamp = moment(this.x).format(momentFormat);
                    let tooltipHtml = `
                        <p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">
                            ${timestamp}
                        </p>`
                    tooltipHtml += "<table>"
                    tooltipHtml += `
                        <tr>
                            <td style="color: white; padding-right: 12px;">
                                ${displayName}
                            </td>
                            <td style="font-weight:bold; text-align: right; color: white">
                                ${Math.round(this.y)} Minutes
                            </td>
                        </tr>`

                    tooltipHtml += "</table>";
                    return tooltipHtml;
                }
            },
            series: [{
                name: 'Alerts',
                data: data,
                pointStart: Date.UTC(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()), // 90 days ago
                pointInterval: 24 * 3600 * 1000, // One data point per day
                lineWidth: 2,
                color: '#3BA272',
                marker: {
                    enabled: false
                }
            }],
            credits: {
                enabled: false
            }
        });
    }

    getAlertStatusHeader(alert) {
        switch (alert.status) {
            case 'TRIGGERED':
                return 'Open - Action Needed'
            case 'ACKNOWLEDGED':
                const event = alert.events.find(e => e.type === 'ACKNOWLEDGED') // eslint-disable-line
                const acknowledgedBy = event.acknowledged.account.displayName || event.acknowledged.account.email // eslint-disable-line
                return `Acknowledged by ${acknowledgedBy}`
            case 'MONITORING':
                return 'Monitoring'
            case 'RESOLVED':
                return 'Resolved'
            case 'ARCHIVED':
                return 'Archived'
            default:
                return alert.status
        }
    }

    getAlertStatusDescription(alert) {
        switch (alert.status) {
            case 'TRIGGERED':
                return this.getRelativeTime(moment(alert.createTime))
            case 'ACKNOWLEDGED':
                const event = alert.events.find(e => e.type === 'ACKNOWLEDGED') // eslint-disable-line
                return this.getRelativeTime(moment(event.createTime))
            case 'MONITORING':
                return this.getRelativeTime(moment(alert.updateTime))
            case 'RESOLVED':
                return this.getRelativeTime(moment(alert.resolveTime))
            case 'ARCHIVED':
                return this.getRelativeTime(moment(alert.archiveTime))
            default:
                return ''
        }
    }

    getRelativeTime(timestamp) {
        // Return the time in a human readable format, but keep it relative if it was solved in the past 4 days
        const now = moment();
        if (now.diff(timestamp, 'days') <= 4) {
            // Eg. "Monday at 3:00 PM"
            return timestamp.calendar(null, {
                sameDay: `[Today] ${getHoursMinutesFormat()}`,
                lastDay: `[Yesterday] ${getHoursMinutesFormat()}`,
                lastWeek: `dddd ${getHoursMinutesFormat()}`,
                sameElse: `MMM D ${getHoursMinutesFormat()}`
            });
        }
        return timestamp.format(`MMM D, ${getHoursMinutesFormat()}`);
    }


    getDuration(alert, asMinutes = false) {
        // Extract the numeric value (seconds) from the string (eg. "3600s" -> 3600)
        const seconds = parseInt(alert.duration, 10);

        // Create a moment.duration object
        const duration = moment.duration(seconds, 'seconds');

        if (asMinutes) {
            return Math.round(duration.asMinutes());
        }
    
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
    
        // Not using moment.js .humanize() because is rounds too much
        // Build the duration string parts
        const parts = [];
        
        if (days > 0) {
            parts.push(`${days} days`);
        }
        if (hours > 0) {
            if (hours === 1) {
                parts.push(`${hours} hr`);
            } else {
                parts.push(`${hours} hrs`);
            }
        }
        if (minutes > 0 && days === 0) { // Only show minutes if less than a day
            parts.push(`${minutes} min`);
        }
    
        // If there are no parts, then the duration is less than a minute
        if (parts.length === 0) {
            return '<1 min';
        }
    
        return parts.join(' ');
    }

    alertHasBeenAcknowledged(alert) {
        return alert.events.some(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
    }

    acknowledgedByUser(alert) {
        const acknowledgedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
        return acknowledgedEvent ? acknowledgedEvent.acknowledged.account.displayName : '';
    }

    alertHasCorrectiveAction(alert) {
        return alert.events.some(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
    }

    correctiveActionByUser(alert) {
        const correctiveActionEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
        return correctiveActionEvent ? correctiveActionEvent.correctiveAction.account.displayName : '';
    }

    alertIcon(alert) {
        let iconName = alert.trigger.field
        if (iconName === 'relativeHumidity') {
            iconName = 'humidity'
        }
        if (iconName === 'connectionStatus') {
            iconName = alert.device.typeIcon
        }
        if (iconName === 'objectPresent') {
            return 'proximity'
        }
        if (iconName === 'waterPresent') {
            return 'waterDetector'
        }
        return iconName
    }

    showTimeline(alert) {

        alert.zoomedIntoAlert = true;

        const showDialog = () => {
            this.DialogService.show({
                controller: TimelineController,
                template: TimelineTemplate,
                controllerAs: '$ctrl',
                parent: document.body,
                clickOutsideToClose: true,
                escapeToClose: true,
                fullscreen: true,
                locals: {
                    alert
                }
            })
        }

        // Check if the alert has been loaded with the device object and alert details
        if (typeof alert.device === 'string') {
            alert.triggerDescription = alertTriggerDescription(alert);
            alert.zoomedIntoAlert = true;
            alert.currentRange = RANGE_LABEL_DAY;
            const deviceId = alert.device.split('/')[3];

            this.SensorService.getFromCache([deviceId]).then(devicesResponse => {
                const devices = devicesResponse.devices;
                const device = devices.find(d => d.name === alert.device);
                alert.device = device;
                showDialog()
            });  
            
        } else {
            showDialog()
        }
    }

    getNumberOfUserInteractions(alert) {
        // Get the number of user interactions (acknowledgements, corrective actions, comments)
        return alert.events.filter(event => {
            return event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED || 
                   event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION || 
                   event.type === ALERT_EVENT_TYPES.COMMENT;
        }).length;
    }

    convertEventsForTimeline(alert) {
        // Convert createTime to human readable format
        alert.events.forEach(event => {
            event.createTimeFormatted = moment(event.createTime).format(`MMM D, ${getHoursMinutesFormat()}`);
            event.timeSince = moment(event.createTime).fromNow();

            // Convert type to a more friendly format
            switch (event.type) {
                case ALERT_EVENT_TYPES.TRIGGERED:
                    event.typeFormatted = 'Open - Active Alert';
                    break;
                case ALERT_EVENT_TYPES.ALERT_DELIVERY_SUCCEEDED:
                    if (event.alertDeliverySucceeded.action.type === 'EMAIL') {
                        event.typeFormatted = `Sent ${event.alertDeliverySucceeded.action.type} to ${event.alertDeliverySucceeded.action.email.recipients[0]}`;
                    } 
                    if (event.alertDeliverySucceeded.action.type === 'SMS') {
                        event.typeFormatted = `Sent ${event.alertDeliverySucceeded.action.type} ${event.alertDeliverySucceeded.action.sms.recipients[0]}`;
                    }
                    break;
                case ALERT_EVENT_TYPES.ALERT_DELIVERY_FAILED:
                    event.typeFormatted = `Failed to deliver ${event.alertDeliveryFailed.action.type}`;
                    break;
                case ALERT_EVENT_TYPES.ACKNOWLEDGED:
                    event.typeFormatted = `Acknowledged by ${event.acknowledged.account.displayName}`;
                    break;
                case ALERT_EVENT_TYPES.CORRECTIVE_ACTION:
                    event.typeFormatted = 'Corrective Action';
                    break;
                case ALERT_EVENT_TYPES.COMMENT:
                    event.typeFormatted = `Comment by ${event.comment.account.displayName}`;
                    break;
                case ALERT_EVENT_TYPES.RESOLVED:
                    event.typeFormatted = 'Resolved';
                    break;
                case ALERT_EVENT_TYPES.ARCHIVED:
                    if (event.archived.description === 'Archived by user') {
                        event.typeFormatted = `Archived by ${event.archived.account.displayName}`;
                    } else {
                        event.typeFormatted = event.archived.description;
                    }
                    break;
                default:
                    event.typeFormatted = event.type;
                    break
            }
        })        

    }

    searchAlertLog() {
        this.singleRuleFilter = null;
        this.searching = true; // Show loading spinner
        const allAlerts = []; // Array to store all fetched alerts
    
        // Function to fetch alerts with pagination
        const fetchSearchAlerts = (pageToken = '') => {
            const params = { 
                searchQuery: this.query,
                startTime: this.startTime.toISOString(),
                endTime: this.endTime.toISOString(),
                projects: [`projects/${this.ProjectManager.currentProjectId}`],
                includeEvents: true
            };
            this.filters.forEach(filter => {
                if (filter.active) {

                    if (filter.displayName === 'Alert Type') {
                        params.triggers = []
                        const triggerFields = filter.options
                            .filter(option => option.active)
                            .map(option => option.value);
            
                        triggerFields.forEach(field => {
                            let fieldName = field;
                            let triggerString = '';
            
                            if (field === 'SENSOR_OFFLINE' || field === 'CLOUD_CONNECTOR_OFFLINE') {
                                fieldName = 'connectionStatus';
                                triggerString = `field:${fieldName},connection:${field}`;
                            } else {
                                triggerString = `field:${fieldName}`;
                            }
            
                            params.triggers.push(triggerString);
                        });
                    }

                    if (filter.displayName === 'Alert Status') {
                        params.statuses = filter.options.filter(option => option.active).map(option => option.value);
                    }

                    if (filter.displayName === 'Corrective Action') {
                        params.correctiveAction = filter.optionValue
                    }

                    if (filter.displayName === 'Acknowledged') {
                        params.acknowledgement = filter.optionValue
                    }

                    if (filter.displayName === 'Duration') {
                        params.maxDuration = filter.options.find(option => option.value === filter.optionValue).maxDuration
                        params.minDuration = filter.options.find(option => option.value === filter.optionValue).minDuration
                    }

                    if (filter.displayName === 'Device Labels') {
                        params.labelFilters = []
                        // Labels are in the format [[key, value], [key, value]]
                        if (filter.labels.length === 1 && filter.labels[0][0] === '' && filter.labels[0][1] === '') {
                            return // No labels to filter on, only the empty placeholder
                        }
                        filter.labels.forEach(label => {
                            if (label[1] === '') { // If the value is empty, only use the key
                                params.labelFilters.push(label[0])
                            } else {
                                params.labelFilters.push(`${label[0]}=${label[1]}`)
                            }
                        });
                    }
                }
            })
            if (pageToken) {
                params.pageToken = pageToken;
            }
    
            // Fetch alerts until all pages are fetched
            return this.AlertsAndRulesService.listAlerts(params).then(response => {
                const alerts = response.data; // Adjust based on response structure
                allAlerts.push(...alerts); // Append current page alerts to allAlerts
    
                if (response.nextPageToken) {
                    // More pages to fetch
                    return fetchSearchAlerts(response.nextPageToken);
                } 
                // All pages fetched
                return Promise.resolve();
            });
        };
    
        // Start fetching alerts
        fetchSearchAlerts().then(() => {
            // Process all fetched alerts
            allAlerts.forEach(alert => {                
                alert.triggerDescription = alertTriggerDescription(alert);
                alert.zoomedIntoAlert = true;
                alert.currentRange = RANGE_LABEL_DAY;
                alert.showOptionsDropdown = false;
                alert.createTimeFormatted = moment(alert.createTime).format(getHoursMinutesFormat());
    
                // If the alert was archived, get the archived event
                if (alert.status === 'ARCHIVED') {
                    alert.archiveTime = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ARCHIVED).createTime;
                }
            });

            // Extract unique device IDs from alert.device paths
            const deviceIds = [...new Set(allAlerts.map(alert => alert.device.split('/')[3]))];
                
            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
    
                allAlerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.deviceId = alert.device.split('/')[3];
                    alert.device = device;
                });
    
                allAlerts.sort((a, b) => {
                    return new Date(b.resolveTime || b.archiveTime || b.createTime) - new Date(a.resolveTime || a.archiveTime || a.createTime);
                });
    
                this.alerts = allAlerts;
                this.groupedAlertsByDay = this.groupAlertsByDay();
                this.groupedAlertsByRule = this.groupAlertsByRule();
                this.loadedAlerts = true;           
                this.searching = false;     
                this.$scope.$applyAsync();
            });  
        });
    }    
    

    showFilterPopover() {
        const button = document.getElementById('alertFilterButton')
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(button)
            .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END , this.$mdPanel.yPosition.BELOW).withOffsetY('12px');;
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(button)
            .withAnimation('md-panel--animation');

        const config = {
            attachTo: document.body,
            controller: FilterController,
            controllerAs: '$ctrl',
            template: FilterTemplate,
            panelClass: 'md-panel-container',
            animation: panelAnimation,
            position,
            openFrom: button,
            clickOutsideToClose: true,
            escapeToClose: true,
            onRemoving: this.closeFilterPopover.bind(this),
            focusOnOpen: true,
            zIndex: 100,
            locals: {
                filters: _cloneDeep(this.filters),
                onUpdateFilterCount: this.updateFilterCount.bind(this),
                onUpdateFilters: this.updateFilters.bind(this),
                onClose: this.closeFilterPopover.bind(this)
            }
        };

        this.$mdPanel.open(config).then(reference => {
            this.panelRef = reference;
        });
    }

    updateFilterCount(count) {
        this.filterCount = count
    }

    updateFilters(filters) {
        this.filters = filters
        this.searchAlertLog()
    }

    clearFilters() {
        this.filters = []
        this.filterCount = 0
        this.panelRef.close()
        this.searchAlertLog()

    }

    closeFilterPopover() {
        this.panelRef.close()
    }

    exportAlertLog() {
        
        
        // Creates a CSV based on the alerts and downloads it automatically 
        let csv = 'Alert Name,Alert ID,Alert Trigger,Alert Status,Device Name,Device ID,Create Time,Resolve Time,Duration,Duration (minutes),Has Been Acknowledged,Acknowledged by,Has Corrective Action,Corrective Action by\r\n';

        this.alerts.forEach(alert => {
            const acknowledgedBy = this.acknowledgedByUser(alert);
            const correctiveActionBy = this.correctiveActionByUser(alert);
            const durationMinutes = this.getDuration(alert, true);
            const durationReadable = this.getDuration(alert);
            const deviceName = alert.device?.labels?.name || 'Device removed from project';
            csv += `${alert.displayName},${alert.id},${alert.triggerDescription},${alert.status},${deviceName},${alert.deviceId || ''},${alert.createTime},${alert.resolveTime || ''},${durationReadable},${durationMinutes},${alert.hasBeenAcknowledged},${acknowledgedBy},${alert.hasCorrectiveAction},${correctiveActionBy}\r\n`;
        });

        const exportedFilename = 'alert_log.csv'
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        this.ToastService.showSimpleTranslated('alert_log_exported');
        
    }

    goToDevice(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3]
        });
    }

    goToAlertRule(alert) {
        this.$state.go(States.ALERTS_RULES_DETAIL, {
            ruleId: alert.rule.split('/')[3]
        });
    }
 
    $onDestroy() {}

}
